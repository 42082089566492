import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authSlice from './authSlice';
import {
    persistStore,
    persistReducer
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import branchSlice from './branchSlice';
import branchProductsSlice from './branchProductsSlice';
import modalSlice from './modalSlice';
import postLoadSlice from './postLoadSlice';
import gridDataSlice from './gridDataSlice';
import documentProductsSlice from './documentProductsSlice';
import productWasteSlice from './productWasteSlice';
import bufferSlice from './bufferSlice';
import userSlice from './userSlice';
import warhouseSlice from './warhouseSlice';
import productSlice from './productSlice';
import vendorSlice from './vendorSlice';

const authPersistConfig = {
    key: 'auth',
    storage,
    whitelist: ['username', 'token', 'role', 'isAuthenticated']
};

const branchPersistConfig = {
    key: 'branch',
    storage,
    whitelist: ['selectedBranch', 'kitchenId', 'dateFilter']
};

const gridPersistConfig = {
    key: 'gridData',
    storage
};

const bufferPersistConfig = {
    key: 'buffer',
    storage,
    whitelist: ['importantBufferData']
};

const vendorPersistConfig = {
    key: 'vendor',
    storage,
    whitelist: ['vendorList', 'productDocumentVendorList']
};

const rootReducer = combineReducers({
    authorization: persistReducer(authPersistConfig, authSlice),
    branch: persistReducer(branchPersistConfig, branchSlice),
    documentProducts: documentProductsSlice,
    branchProducts: branchProductsSlice,
    gridData: persistReducer(gridPersistConfig, gridDataSlice),
    productWaste: productWasteSlice,
    buffer: persistReducer(bufferPersistConfig, bufferSlice),
    modal: modalSlice,
    postLoad: postLoadSlice,
    vendor: persistReducer(vendorPersistConfig, vendorSlice),
    user: userSlice,
    warhouse: warhouseSlice,
    product: productSlice
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
});

export const persistor = persistStore(store);