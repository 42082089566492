import { createAsyncThunk } from "@reduxjs/toolkit"
import { documentVendorProductEditUnit, documentVendorProductUnit, vendorProductUnit } from "../units/supplyGetUnits"
import { createVendorDocumentUnit } from "../units/supplyPostUnits"
import { deleteVendorDocumentUnit, getVendorListUnit, updateVendorDocumentUnit, vendorCreateUnit } from "../units/vendorUnits"




export const vendorListAction = createAsyncThunk('vendor/lsit', async(_, {dispatch, rejectWithValue}) => {
    try {
        const response = await getVendorListUnit()
        return response
    } catch (e){
        return rejectWithValue(e.request?.response || 'Some error')
    }
})


export const vendorProductsAction = createAsyncThunk('vendor/products', async(_, {dispatch, rejectWithValue}) => {
    try {
        const response = await vendorProductUnit()
        return response
    } catch (e){
        return rejectWithValue(e.request?.response || 'Some error')
    }
})


export const DocumentVendorProductsAction = createAsyncThunk('document/vendor/products', async(params, {dispatch, rejectWithValue}) => {
    try {
        const response = await documentVendorProductUnit(params)
        return response
    } catch (e){
        return rejectWithValue(e.request?.response || 'Some error')
    }
})

export const documentVendorProductsEditAction = createAsyncThunk('edit/document/vendor/products', async(params, {dispatch, rejectWithValue, getState}) => {
    try {
        const response = await documentVendorProductEditUnit(params)
        const state = getState()
        const date = state.vendor.dateFilter.date
        const {branchId, branchType} = state.vendor.branchFilter
        if (branchId !== 0 && branchType !== 0){
            dispatch(DocumentVendorProductsAction({date, branchId, branchType}))
        } else {
            dispatch(DocumentVendorProductsAction({date}))
        }
        return response
    } catch (e){
        return rejectWithValue(e.request?.response || 'Some error')
    }
})



export const createVendorDocumentAction = createAsyncThunk('create/vendordocument', async(data, {dispatch, rejectWithValue}) => {
    try {
        const body = data.map(el => ({product: el.id, quantity: el.quantity, price: el.avg_price}))
        const response = await createVendorDocumentUnit(body)
        return response
    } catch (e){
        return rejectWithValue(e.request?.response || 'Some error')
    }
})


export const updateVendorDocumentAction = createAsyncThunk('update/vendordocument', async(data, {dispatch, getState, rejectWithValue}) => {
    try {
        const response = await updateVendorDocumentUnit(data)
        const state = getState()
        const date = state.vendor.dateFilter.date
        const {branchId, branchType} = state.vendor.branchFilter
        if (branchId !== 0 && branchType !== 0){
            dispatch(DocumentVendorProductsAction({date, branchId, branchType}))
        } else {
            dispatch(DocumentVendorProductsAction({date}))
        }
        return response
    } catch (e){
        return rejectWithValue(e.request?.response || 'Some error')
    }
})



export const deleteVendorDocumentAction = createAsyncThunk('delete/vendordocument', async(document_id, {dispatch, getState, rejectWithValue}) => {
    try {
        const response = await deleteVendorDocumentUnit(document_id)
        const state = getState()
        const date = state.vendor.dateFilter.date
        const {branchId, branchType} = state.vendor.branchFilter
        if (branchId !== 0 && branchType !== 0){
            dispatch(DocumentVendorProductsAction({date, branchId, branchType}))
        } else {
            dispatch(DocumentVendorProductsAction({date}))
        }
        return response
    } catch (e){
        return rejectWithValue(e.request?.response || 'Some error')
    }
})


export const createVendorAction = createAsyncThunk('create/vendor', async (data, {rejectWithValue}) => {
    try {
        const response = await vendorCreateUnit(data)
        return response
    } catch(e){
        return rejectWithValue(e.request?.response || 'Some error')
    }
})
