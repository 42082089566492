import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { getDocumentProductsAction } from "../asyncActions/documentActions"
import { Box, Grid2, Typography } from "@mui/material"
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import DocuemntViewGrid from "../components/DocumentViewGrid"
import DocumentEditGrid from "../components/DocumentEditGrid"
import { useTranslation } from "react-i18next"


function DocumentPage() {

    const { t } = useTranslation()

    const {
        id
    } = useParams()

    const {
        document
    } = useSelector(store => store.documentProducts)

    const {
        role
    } = useSelector(store => store.authorization)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getDocumentProductsAction(id))
    }, [])

    const status = {
        1: t('page_text.status.created'),
        2: t('page_text.status.edited'),
        3: t('page_text.status.delivery'),
        4: t('page_text.status.archived'),
    }

    return (
        <Box sx={{
            width: '100%', 
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Box sx={{
                padding: '20px',
                backgroundColor: '#f7f7f7',
                borderRadius: '12px',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                fontFamily: 'Roboto, sans-serif',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '10px',
            }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
                    {t('page_text.document_detail')}
                </Typography>
                <Grid2 container spacing={5} justifyContent={'center'}>
                    <Grid2 item xs={6}>
                        <Typography variant="body1">
                            <strong>{t('page_text.doc_date')}:</strong> {document.detail_date}
                        </Typography>
                        <Typography variant="body1">
                            <strong>{t('page_text.doc_author')}:</strong> {document.author?.username || 'Unknown'}
                        </Typography>
                    </Grid2>
                    <Grid2 item xs={6}>
                        <Typography variant="body1">
                            <strong>ID:</strong> {document.id}
                        </Typography>
                        <Typography variant="body1">
                            <strong>{t('page_text.doc_status')}:</strong> {status[document.status]}
                        </Typography>
                    </Grid2>
                    {document.isDeclined && (
                        <Grid2 item xs={6}>
                            <Typography variant="body1" sx={{ color: 'red', fontWeight: 'bold' }}>
                                Declined
                            </Typography>
                        </Grid2>
                    )}
                </Grid2>
                {document.unscheduled &&
                    <Box sx={{ display: 'flex', gap: 3 }}>
                        <Typography variant="body1" sx={{ color: 'red', fontWeight: 'bold' }}>
                            Unscheduled
                        </Typography>
                        <RocketLaunchIcon sx={{ color: 'red' }} />
                    </Box>
                }
            </Box>
            <Box sx={{height: '100%'}}>
                {[1,3,4].includes(role) ?
                    <DocuemntViewGrid /> :
                    <DocumentEditGrid />
                }
            </Box>

        </Box>
    )
}

export default DocumentPage