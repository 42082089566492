import { createAsyncThunk } from "@reduxjs/toolkit"
import { categoryListUnit } from "../units/categoryUnits"



export const getCategoryListAction = createAsyncThunk('get/category/list', async (_, {rejectWithValue}) => {
    try {
        const response = await categoryListUnit()
        return response
    } catch(e){
        return rejectWithValue(e.request?.response || 'Some error')
    }
})