import { Box, Typography } from "@mui/material";
import SubmitButton from "../../../UI/SubmitButton";
import EntryButton from "../../../UI/EntryButton";
import { useDispatch, useSelector } from "react-redux";
import { setModal } from "../../../store/modalSlice";
import { useTranslation } from "react-i18next";
import { createProductAction, createVendorProductAction, updateProductAction, updateVendorProductAction } from "../../../asyncActions/productAction";

function ProductEditContent({ type }) {
    const { t } = useTranslation()

    const dispatch = useDispatch()

    const {
        data
    } = useSelector(store => store.modal)

    const submitHandle = () => {
        const modalPayload = {
            active: true,
            content: 'action',
            data: {
                path: data.path
            }
        }
        if (type === 'create') {
            modalPayload.data.text = (data.type === 'product') ? 'Продукт создан!' : 'Продукт вендора создан!'
            dispatch(setModal(modalPayload))
            if (data.type === 'product') {
                dispatch(createProductAction(data.payload));
            } else if (data.type === 'vendorProduct') {
                dispatch(createVendorProductAction(data.payload))
            }
        } else if (type === 'edit') {
            modalPayload.data.text = (data.type === 'product') ? 'Продукт изменен!' : 'Продукт вендора изменен!'
            dispatch(setModal(modalPayload))
            if (data.type === 'product') {
                dispatch(updateProductAction(data.payload))
            } else if (data.type === 'vendorProduct') {
                dispatch(updateVendorProductAction(data.payload))
            }
        }
    }

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '25px',
            width: '450px'
        }}>
            <Typography variant="h6">{data.text}</Typography>
            <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'space-between' }}>
                <EntryButton onClick={() => dispatch(setModal({ active: false }))}>{t('button.cancel')}</EntryButton>
                <SubmitButton onClick={submitHandle}>{t('button.submit')}</SubmitButton>
            </Box>
        </Box>
    )
}


export default ProductEditContent
