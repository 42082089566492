import { Box, TextField, MenuItem, Button, FormControl, InputLabel, Select, Autocomplete, Typography, FormControlLabel, Checkbox } from "@mui/material";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import EntryButton from "../../UI/EntryButton";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryListAction } from "../../asyncActions/categoryAction";
import { vendorProductsAction } from "../../asyncActions/vendorActions";
import SubmitButton from "../../UI/SubmitButton";
import { allWarehouseListAction } from "../../asyncActions/warhouseActions";
import { getProductItemAction } from "../../asyncActions/productAction";
import { clearForm } from "../../store/productSlice";
import { setModal } from "../../store/modalSlice";

function ProductEditPage() {
    const { id } = useParams();
    const type = id ? 'edit' : 'create';
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { control, handleSubmit, setValue, formState: { errors }, reset } = useForm({
        defaultValues: {
            'is_wasted': false,
            'in_document': false,
        }
    });

    const { categoryList, vendorProductList, branchList, form } = useSelector(store => store.product.product);

    const { fields, append, remove } = useFieldArray({
        control,
        name: "vendorProducts"  // It's an array of vendor products
    });

    useEffect(() => {
        dispatch(getCategoryListAction());
        dispatch(allWarehouseListAction());
        dispatch(vendorProductsAction());
        if (type === 'edit' && id) {
            dispatch(getProductItemAction(id));
        }
        return () => {
            dispatch(clearForm())
        }
    }, [dispatch, id, type]);

    const vendorProductOptions = vendorProductList.map(el => ({ label: el.name, value: el.id }));
    const branchOptions = branchList.map(el => ({ label: el.name, value: el.id }));

    useEffect(() => {
        if (form?.vendorProducts && vendorProductList.length && categoryList.length) {
            const findCategory = categoryList.find(el => el.id == form.category)
            setValue("name", form.name || "");
            setValue("category", findCategory.id || "");
            setValue("unit_type", form.unit_type || "")
            setValue("is_wasted", form.is_wasted || false)
            setValue("in_document", form.in_document || false)
            setValue("branch", form.branch);

            form.vendorProducts.forEach((product, index) => {
                append({ product_vendor_id: vendorProductList.find(el => el.id === product.product_vendor_id).id, conversion_rate: product.conversion_rate });
            });
        }
        return () => {
            remove()
        }
    }, [form, vendorProductList, categoryList]);

    const onSubmit = (data) => {
        let modalPayload = {
            active: true,
            data: {
                path: '/product',
                type: 'product'
            }
        }
        if (type === 'create') {
            modalPayload.data.text = 'Подтвердить создание продукта'
            modalPayload.content = 'productCreate'
            modalPayload.data.payload = data
            dispatch(setModal(modalPayload))
        } else if (type === 'edit') {
            const param = {
                id,
                body: data
            }
            modalPayload.data.text = 'Подтвердить изменение продукта'
            modalPayload.content = 'productEdit'
            modalPayload.data.payload = param
            dispatch(setModal(modalPayload))
        }
    };


    return (
        <Box sx={{ width: '100%', maxWidth: '800px', margin: 'auto', p: 2 }}>
            <Typography variant="h4">{type === 'edit' ? `Edit product ${form?.name}` : "Create new product"}</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'start', my: 2 }}>
                <EntryButton onClick={() => navigate(-1)}>Back</EntryButton>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <box>
                    <Controller
                        name={'is_wasted'}
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                control={<Checkbox {...field} checked={field.value || false} onChange={(e) => setValue('is_wasted', e.target.checked)} />}
                                label="Can wasted"
                            />
                        )}
                    />
                    <Controller
                        name={'in_document'}
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                control={<Checkbox {...field} checked={field.value || false} onChange={(e) => setValue('in_document', e.target.checked)} />}
                                label="In document"
                            />
                        )}
                    />
                </box>
                <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Имя продукта обязательно" }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            fullWidth
                            label="Имя"
                            margin="normal"
                            error={!!errors.name}
                            helperText={errors.name?.message}
                        />
                    )}
                />

                <FormControl fullWidth margin="normal">
                    <InputLabel>Категория</InputLabel>
                    <Controller
                        name="category"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Категория обязательна" }}
                        render={({ field }) => (
                            <Select
                                {...field}
                                label="Категория"
                                error={!!errors.category}
                            >
                                {categoryList.map(cat => (
                                    <MenuItem key={cat.id} value={cat.id}>{cat.name}</MenuItem>
                                ))}
                            </Select>
                        )}
                    />
                    {errors.category && <p style={{ color: 'red' }}>{errors.category.message}</p>}
                </FormControl>

                <Controller
                    name="unit_type"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Тип обязателен" }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            fullWidth
                            label="Тип"
                            margin="normal"
                            error={!!errors.unit_type}
                            helperText={errors.unit_type?.message}
                        />
                    )}
                />
                {/* Многовыборочный Select для привязанных точек */}
                <FormControl fullWidth margin="normal">
                    <InputLabel>Привязанные точки</InputLabel>
                    <Controller
                        name="branch"
                        control={control}
                        rules={{
                            required: "Выбор точек обязателен",
                            validate: value => value?.length > 0 || "Выберите хотя бы одну точку"
                        }}
                        render={({ field }) => (
                            <Select
                                {...field}
                                label="Привязанные точки"
                                multiple
                                error={!!errors.branch}
                                defaultValue={[]}
                                value={field.value || []}
                            >
                                {branchOptions.map((point) => (
                                    <MenuItem key={point.value} value={point.value}>{point.label}</MenuItem>
                                ))}
                            </Select>
                        )}
                    />
                    {errors.branch && <p style={{ color: 'red' }}>{errors.branch.message}</p>}
                </FormControl>

                <Box sx={{
                    border: '1px solid gray',
                    p: 4,
                    borderRadius: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 3
                }}>
                    <SubmitButton onClick={() => append({})}>
                        Добавить связь
                    </SubmitButton>

                    {/* Section for multiple vendor products */}
                    <FormControl sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2
                    }}>
                        {fields.map((item, index) => (
                            <Box key={item.id} sx={{ display: 'flex', width: '100%', alignItems: 'center', gap: 3, py: 1 }}>
                                <Controller
                                    name={`vendorProducts[${index}].product_vendor_id`}
                                    control={control}
                                    rules={{ required: "Продукт обязательно" }}
                                    render={({ field }) => (
                                        <Autocomplete
                                            {...field}
                                            label="Продукт"
                                            options={vendorProductOptions}
                                            onChange={(e, newValue) => setValue(`vendorProducts[${index}].product_vendor_id`, newValue?.value || "")}
                                            value={vendorProductOptions.find(option => option.value === field.value) || null}
                                            renderInput={(params) => <TextField required {...params} label="Выберите продукт" />}
                                            fullWidth
                                        />
                                    )}
                                />
                                <Controller
                                    name={`vendorProducts[${index}].conversion_rate`}
                                    control={control}
                                    rules={{
                                        required: "Конверция обязательно",
                                        valueAsNumber: true,
                                        min: { value: 1, message: "Минимальное количество - 1" }
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Конверция"
                                            type="number"
                                            fullWidth
                                            error={!!errors.vendorProducts?.[index]?.conversion_rate}
                                        />
                                    )}
                                />
                                <EntryButton
                                    onClick={() => remove(index)}
                                    sx={{
                                        px: 5,
                                        height: '100%'
                                    }}
                                >
                                    Удалить
                                </EntryButton>
                            </Box>
                        ))}
                    </FormControl>
                </Box>

                {/* Валидация на хотя бы одну связь */}
                <FormControl fullWidth margin="normal">
                    <Controller
                        name="vendorProducts"
                        control={control}
                        rules={{
                            validate: value => value?.length > 0 || "Добавьте хотя бы одну связь с продуктом"
                        }}
                        render={({ field }) => (
                            <></> // Просто пустой компонент для валидации
                        )}
                    />
                    {errors.vendorProducts && <p style={{ color: 'red' }}>{errors.vendorProducts.message}</p>}
                </FormControl>

                <SubmitButton type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
                    {type === 'edit' ? "Сохранить изменения" : "Создать продукт"}
                </SubmitButton>
            </form>
        </Box>
    );
}

export default ProductEditPage;
