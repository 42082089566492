import { createAsyncThunk } from "@reduxjs/toolkit"
import { createVendorProductUnit, productCreateUnit, productDeleteUnit, productItemUnit, productListUnit, productUpdateUnit, vendorProductItemUnit, vendorProductListUnit, vendorProductUpdateUnit } from "../units/productUnits"
import { categoryCreateUnit } from "../units/categoryUnits"



export const getProductListAction = createAsyncThunk('get/product/list', async (_, {rejectWithValue}) => {
    try {
        const response = await productListUnit()
        return response
    } catch(e){
        return rejectWithValue(e.request?.response || 'Some error')
    }
})


export const createProductAction = createAsyncThunk('create/product', async (body, {rejectWithValue}) => {
    try {
        const response = await productCreateUnit(body)
        return response
    } catch(e){
        return rejectWithValue(e.request?.response || 'Some error')
    }
})


export const deleteProductAction = createAsyncThunk('delete/product', async (id, {rejectWithValue}) => {
    try {
        const response = await productDeleteUnit(id)
        return response
    } catch(e){
        return rejectWithValue(e.request?.response || 'Some error')
    }
})


export const updateProductAction = createAsyncThunk('update/product', async (data, {rejectWithValue}) => {
    try {
        const response = await productUpdateUnit(data)
        return response
    } catch(e){
        return rejectWithValue(e.request?.response || 'Some error')
    }
})


export const getProductItemAction = createAsyncThunk('get/product/item', async (id, {rejectWithValue}) => {
    try {
        const response = await productItemUnit(id)
        return response
    } catch(e){
        return rejectWithValue(e.request?.response || 'Some error')
    }
})


export const getVendorProductListAction = createAsyncThunk('get/vendor/product/list', async (_, {rejectWithValue}) => {
    try {
        const response = await vendorProductListUnit()
        return response
    } catch(e){
        return rejectWithValue(e.request?.response || 'Some error')
    }
})

export const createVendorProductAction = createAsyncThunk('create/vendor/product', async(body, {dispatch, rejectWithValue}) => {
    try {
        const response = await createVendorProductUnit(body)
        return response
    } catch (e){
        return rejectWithValue(e.request?.response || 'Some error')
    }
})

export const getVendorProductItemAction = createAsyncThunk('get-vendor/product/item', async (id, {rejectWithValue}) => {
    try {
        const response = await vendorProductItemUnit(id)
        return response
    } catch(e){
        return rejectWithValue(e.request?.response || 'Some error')
    }
})

export const updateVendorProductAction = createAsyncThunk('update/product', async (data, {rejectWithValue}) => {
    try {
        const response = await vendorProductUpdateUnit(data)
        return response
    } catch(e){
        return rejectWithValue(e.request?.response || 'Some error')
    }
})



export const createCategoryAction = createAsyncThunk('create/category', async (data, {rejectWithValue}) => {
    try {
        const response = await categoryCreateUnit(data)
        return response
    } catch(e){
        return rejectWithValue(e.request?.response || 'Some error')
    }
})
