import { Box, Typography } from "@mui/material";
import SubmitButton from "../UI/SubmitButton";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import FileOpenIcon from '@mui/icons-material/FileOpen';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { useTranslation } from "react-i18next";

const monthsArmenian = [
    "Հունվար", "Փետրվար", "Մարտ", "Ապրիլ", "Մայիս", "Հունիս",
    "Հուլիս", "Օգոստոս", "Սեպտեմբեր", "Հոկտեմբեր", "Նոյեմբեր", "Դեկտեմբեր"
];


function formatGetterDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth();
    const monthName = monthsArmenian[month];
    return `${day} ${monthName}`;
}

function DocumentItem({ document }) {

    const {t} = useTranslation()

    const {
        status,
        unscheduled
    } = document

    const {
        role
    } = useSelector(store => store.authorization)

    const styleBorderStatus = {
        1: {
            1: {
                boxShadow: '0px 5px 15px 0px rgba(98,255,76,1)',
            },
            2: {
                boxShadow: '0px 5px 15px 0px rgba(245,166,35,1)',
            },
            3: {
                boxShadow: '0px 5px 15px 0px rgba(245,166,35,1)',
            },
            4: {
                boxShadow: '0px 5px 15px 0px rgba(98,255,76,1)',
            },
        },
        2: {
            1: {
                boxShadow: '0px 5px 15px 0px rgba(245,166,35,1)',
            },
            2: {
                boxShadow: '0px 5px 15px 0px  rgba(98,255,76,1)',
            },
            3: {
                boxShadow: '0px 5px 15px 0px  rgba(98,255,76,1)',
            },
            4: {
                boxShadow: '0px 5px 15px 0px  rgba(98,255,76,1)',
            },
        },
        3: {
            2: {
                boxShadow: '0px 5px 15px 0px rgba(245,166,35,1)',
            },
            3: {
                boxShadow: '0px 5px 15px 0px  rgba(98,255,76,1)',
            },
        },
        4: {
            1: {
                boxShadow: '0px 5px 15px 0px rgba(98,255,76,1)',
            },
            2: {
                boxShadow: '0px 5px 15px 0px rgba(245,166,35,1)',
            },
            3: {
                boxShadow: '0px 5px 15px 0px rgba(245,166,35,1)',
            },
            4: {
                boxShadow: '0px 5px 15px 0px rgba(98,255,76,1)',
            },
        },
        5: {
            1: {
                boxShadow: '0px 5px 15px 0px rgba(245,166,35,1)',
            },
            2: {
                boxShadow: '0px 5px 15px 0px rgba(245,166,35,1)',
            },
            3: {
                boxShadow: '0px 5px 15px 0px rgba(245,166,35,1)',
            },
            4: {
                boxShadow: '0px 5px 15px 0px rgba(98,255,76,1)',
            },
        }
    }

    let declinedStyle = {
        boxShadow: '0px 5px 15px 0px rgb(255 2 2)',
    }

    const formattedDate = new Date(document.detail_date).toLocaleString('hy-AM', { month: 'long', day: 'numeric' });


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '15px',
                gap: 3,
                borderRadius: '16px',
                boxShadow: (document.isDeclined) ? declinedStyle.boxShadow : styleBorderStatus[role]?.[status]?.boxShadow,
                border: '0.5px solid #f1f1f1',
            }}
            key={document.id}>
            <Typography variant="h6">
                {formatGetterDate(document.detail_date)}
            </Typography>
            <Box sx={{
                display: 'flex',
                gap: '15px',
                alignItems: 'center'
            }}>
                <Typography>{document.branch?.name} </Typography>
                {unscheduled && <RocketLaunchIcon sx={{ color: 'red' }} />}
            </Box>
            <Link to={'/document/' + document.id}>
                <SubmitButton endIcon={<FileOpenIcon />}>{t('button.open')}</SubmitButton>
            </Link>
        </Box>
    )
}


export default DocumentItem