import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Box, Typography } from "@mui/material"
import DocumentList from "./DocumentList"

import { getDocumentListAction } from "../asyncActions/documentActions"
import { useTranslation } from "react-i18next"


function ManagerHomePage(){

    const {t} = useTranslation()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getDocumentListAction())
    }, [])

    const {
        documents
    } = useSelector(store => store.branch)
    
    const [yesterdayDate, todayDate] = new Array(...new Set(documents.map(el => el.detail_date))).sort()

    const yesterdayDocuments = documents.filter(el => el.detail_date === yesterdayDate )
    const todayDocuments = documents.filter(el => el.detail_date === todayDate )
        
    return(
        <Box sx={{
            display: 'flex', 
            flexDirection: 'column', 
            gap: 5,
            width: '100%'
        }}>
            <Box sx={{
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
                gap: 3,
                border: '1px solid #bebebe',
                borderRadius: '16px',
                padding: '25px'
            }}>
                <Typography variant="h4">
                    {/* {t('page_text.active_document')} */}
                    Yesterday's documents
                </Typography>
                {yesterdayDocuments.length === 0 ? 
                    <Typography>{t('page_text.no_branch_document')}</Typography>
                    :
                    <DocumentList documents={yesterdayDocuments}/>
                }
            </Box>
            <Box sx={{
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
                gap: 3,
                border: '1px solid #bebebe',
                borderRadius: '16px',
                padding: '25px'
            }}>
                <Typography variant="h4">
                    {/* {t('page_text.active_document')} */}
                    Today's documents
                </Typography>
                {todayDocuments.length === 0 ? 
                    <Typography>{t('page_text.no_branch_document')}</Typography>
                    :
                    <DocumentList documents={todayDocuments}/>
                }
            </Box>                  
        </Box>
    )
}


export default ManagerHomePage
