import { createSlice } from "@reduxjs/toolkit";
import { getProductItemAction, getProductListAction, getVendorProductItemAction, getVendorProductListAction } from "../asyncActions/productAction";
import { getCategoryListAction } from "../asyncActions/categoryAction";
import { vendorListAction, vendorProductsAction } from "../asyncActions/vendorActions";
import { allWarehouseListAction } from "../asyncActions/warhouseActions";


const productSlice = createSlice({
    name: 'productSlice',
    initialState: {
        product: {
            form: null,
            productList: [],
            categoryList: [],
            vendorProductList: [],
            branchList: []
        },
        vendorProduct: {
            form: null,
            vendorList: [],
            productList: []
        },
        dataLoad: false,
        error: null
    },
    reducers: {
        clearForm(state){
            state.product.form = null
            state.vendorProduct.form = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProductListAction.fulfilled, (state,action) => {
                state.product.productList = action.payload.sort((a,b) => a.category.id - b.category.id)
                state.dataLoad = false
            })
            .addCase(getProductListAction.pending, (state) => {
                state.dataLoad = true
                state.error = null
            })
            .addCase(getProductListAction.rejected, (state,action) => {
                state.dataLoad = false
                try {
                    state.error = JSON.parse(action.payload).detail || 'Some error';
                } catch (e) {
                    state.error = action.payload || 'Some error';
                }
            })
            .addCase(getCategoryListAction.fulfilled, (state,action) => {
                state.product.categoryList = action.payload
            })
            .addCase(vendorProductsAction.fulfilled, (state,action) => {
                state.product.vendorProductList = action.payload.map(el => ({...el, name: `${el.name} {${el.unit_type}}`}))
            })
            .addCase(allWarehouseListAction.fulfilled, (state,action) => {
                state.product.branchList = action.payload.branches
            })
            .addCase(getProductItemAction.fulfilled, (state, action) => {
                state.product.form = action.payload
            })


            .addCase(getVendorProductListAction.fulfilled, (state,action) => {
                state.vendorProduct.productList = action.payload
                state.dataLoad = false
            })
            .addCase(getVendorProductListAction.pending, (state) => {
                state.dataLoad = true
                state.error = null
            })
            .addCase(getVendorProductListAction.rejected, (state,action) => {
                state.dataLoad = false
                try {
                    state.error = JSON.parse(action.payload).detail || 'Some error';
                } catch (e) {
                    state.error = action.payload || 'Some error';
                }
            })
            .addCase(vendorListAction.fulfilled, (state,action) => {
                state.vendorProduct.vendorList = action.payload
            })
            
            .addCase(getVendorProductItemAction.fulfilled, (state, action) => {
                state.vendorProduct.form = action.payload
            })
            
            
    }
})

export default productSlice.reducer

export const {
    clearForm
} = productSlice.actions