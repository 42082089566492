import { Box } from "@mui/material"


function StyledContainer({children}){
    return(
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundColor: 'white',
            borderRadius: '16px',
            padding: '15px',
            margin: '30px 0px',
            gap: 2,
            height: 'max-content'
        }}>
            {children}
        </Box>
    )
}


export default StyledContainer