import { Box, TextField, Typography } from "@mui/material";
import SubmitButton from "../../../UI/SubmitButton";
import EntryButton from "../../../UI/EntryButton";
import { useDispatch, useSelector } from "react-redux";
import { setModal } from "../../../store/modalSlice";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { createCategoryAction } from "../../../asyncActions/productAction";
import { createVendorAction } from "../../../asyncActions/vendorActions";

function CreateVendorContent() {
    const { t } = useTranslation()

    const dispatch = useDispatch()

    const {
        handleSubmit,
        control,
        formState: {errors}
    } = useForm()

    const submitHandle = (data) => {
        const modalPayload = {
            active: true,
            content: 'action',
            data: {
                text: 'Вендор создан!',
                path: '/vendor/product'
            }
        }
        dispatch(createVendorAction(data))
        dispatch(setModal(modalPayload))
    }

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '15px',
            width: '450px'
        }}>
            <Typography variant="h6">Укажите нового вендора</Typography>
            <form onSubmit={handleSubmit(submitHandle)}>
            <Box>
                <Controller
                        name="name"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Имя вендора обязательно" }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                fullWidth
                                label="Имя"
                                margin="normal"
                                error={!!errors.name}
                                helperText={errors.name?.message}
                            />
                        )}
                    />
            </Box>
            <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'space-between' }}>
                <EntryButton type='button' onClick={() => dispatch(setModal({ active: false }))}>{t('button.cancel')}</EntryButton>
                <SubmitButton type='submit'>{t('button.submit')}</SubmitButton>
            </Box>
            </form>

        </Box>
    )
}


export default CreateVendorContent
