import { instance } from "./interceptors"


export const productListUnit = async() => {
    const response = await instance.get('/api/product/list')
    return response.data
}

export const productCreateUnit = async(body) => {
    const response = await instance.post('/api/product/create', body)
    return response.data
}


export const productDeleteUnit = async(id) => {
    const response = await instance.delete('/api/product/'+id)
    return response.data
}


export const productUpdateUnit = async(data) => {
    const id = data.id
    const body = data.body
    const response = await instance.put('/api/product/update/'+id, body)
    return response.data
}


export const productItemUnit = async(id) => {
    const response = await instance.get('/api/product/get/'+id)
    return response.data
}



export const vendorProductListUnit = async() => {
    const response = await instance.get('/api/vendor-product/list')
    return response.data
}

export const createVendorProductUnit = async(body) => {
    const response = await instance.post('/api/vendor-product/create', body)
    return response
}

export const vendorProductItemUnit = async(id) => {
    const response = await instance.get('/api/vendor-product/get/'+id)
    return response.data
}


export const vendorProductUpdateUnit = async(data) => {
    const id = data.id
    const body = data.body
    const response = await instance.put('/api/vendor-product/update/'+id, body)
    return response.data
}


