import axios from 'axios'
import { logout } from '../store/authSlice';


export const BASE_URL = 'https://admin.ringosupply.com/'; 
// export const BASE_URL = 'http://localhost:8000/'; 
 

export const instance = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    }
})

instance.interceptors.request.use(config => {
    const token = JSON.parse(localStorage.getItem('persist:auth')).token
    if (token){
        config.headers.Authorization = `Bearer ${token.replaceAll('"','')}`
    }
    return config
    }, error => {
        return Promise.reject(error)
})


instance.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            const { store } = require('../store');
            store.dispatch(logout());
        }
        return Promise.reject(error); 
    }
);