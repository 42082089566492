import { Autocomplete, Box, FormControl, Icon, TextField, Typography } from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SubmitButton from "../UI/SubmitButton";
import { useEffect, useState, useCallback } from "react";
import EntryButton from "../UI/EntryButton";
import { useDispatch, useSelector } from "react-redux";
import { addUnscheduleDocumentData, removeUnscheduleDocumentData, } from "../store/gridDataSlice";
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import { allWarehouseListAction, warhouseProductsAction } from "../asyncActions/warhouseActions";
import { useForm } from "react-hook-form";
import { vendorProductsAction } from "../asyncActions/vendorActions";
import { setModal } from "../store/modalSlice";


function TransferPage() {
    const apiRef = useGridApiRef()


    const dispatch = useDispatch()

    const [rows, setRows] = useState([])
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [selectedBranch, setSelectedBranch] = useState(null)


    const {
        branchId,
    } = useSelector(store => store.branch)

    const {
        branchProducts
    } = useSelector(store => store.branchProducts)

    const {
        branchList,
        products
    } = useSelector(store => store.warhouse)

    const [error, setError] = useState(null)

    const columns = [
        {
            field: 'product_type_id',
            headerName: 'id',
            maxWidth: 100,
            flex: 1
        },
        {
            field: 'product_name',
            headerName: 'Name',
            minWidth: 250,
            flex: 1
        },
        {
            field: 'unit_type',
            headerName: 'Unit type',
            maxWidth: 100,
            flex: 1
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            editable: true,
            type: 'number',
            minWidth: 80,
            flex: 1
        },
        {
            field: 'action',
            type: 'actions',
            headerName: 'Action',
            maxWidth: 100,
            flex: 1,
            renderCell: (params) => {
                const id = params.row.product_type_id
                return (
                    <Box>
                        <Icon component={DeleteIcon} onClick={() => {
                            setRows(rows.filter(el => el.product_type_id !== id))
                        }} />
                    </Box>
                )
            }
        },
    ]

    const handleSelectProduct = (data) => {
        const product = {
            product_name: data.label,
            product_id: data.id,
            type: data.type,
            unit_type: data.unit_type,
            product_type_id: `${data.type}${data.id}`
        }
        setSelectedProduct(product)
    }


    function addHandle() {
        setError(null)
        if (selectedProduct) {
            console.log(selectedProduct)
            if (rows.some(el => el.product_type_id === `${selectedProduct.type}${selectedProduct.product_id}`)) {
                setError('Product was added')
            } else {
                setRows((state) => [...state, selectedProduct])
            }
        } else {
            setError('Product requared!')
        }
    }
    function onSubmit() {
        if (selectedBranch){
            setTimeout(() => {
                let editedRow = new Array(...apiRef.current.getRowModels()).map(el => el[1])
                if (editedRow.every(el => typeof el.quantity === 'number')){
                    const data = {
                        branch: selectedBranch,
                        products: editedRow
                    }
                    const modalContent = {
                        active: true,
                        content: 'createTransfer',
                        data
                    }
                    dispatch(setModal(modalContent))
                } else {
                    setError('Quantity 0 not allowed')
                }                
            }, 10)
        } else {
            setError('Branch requared')
        }
    }


    function setClear() {
        setRows([])

    }

    useEffect(() => {
        dispatch(warhouseProductsAction())
        dispatch(allWarehouseListAction())
    }, [dispatch])

    const handleCellClick = useCallback((params) => {
        try {
            apiRef.current.startCellEditMode({
                id: params.id,
                field: params.field,
            })
        } catch (e) {
        }
    },
        [apiRef],
    );

    const productOptions = products.map(el => ({ label: el.name, id: el.product_id, type: el.type, unit_type: el.unit_type }))

    const branchOption = branchList.filter(el => el.id != branchId).map(el => ({ label: el.name, id: el.id }))

    return (
        <Box sx={{
            width: '100%',
            height: '100%',

        }}>
            <Box type="form" sx={{
                display: 'flex',
                height: '100%',
                flexDirection: 'column',
                gap: 2
            }}>
                <Typography variant="h4" align="center">Transfer</Typography>
                {error && <Typography align="center" sx={{ color: 'red' }}>{error}</Typography>}
                <Box
                    sx={{
                        display: "flex",
                        gap: 5,
                        justifyContent: 'center'
                    }}
                >
                    <FormControl>
                        <Autocomplete
                            options={branchOption}
                            sx={{ width: "300px" }}
                            onChange={(_,data) => setSelectedBranch(data?.id ?? null)}
                            renderInput={(params) => {
                                return <TextField
                                    {...params}
                                    label="Branch"
                                />
                            }}
                        />
                    </FormControl>
                    <FormControl>
                        <Autocomplete
                            options={productOptions}
                            sx={{ width: "300px" }}
                            onChange={(_, data) => handleSelectProduct(data)}
                            renderInput={(params) => {
                                return <TextField
                                    {...params}
                                    label="Products"
                                />
                            }}
                        />
                    </FormControl>

                    <SubmitButton endIcon={<AddIcon />} onClick={addHandle}> Add</SubmitButton>

                </Box>
                <Box sx={{ height: '100%' }}>
                    <DataGrid
                        getRowId={(row) => (row.product_type_id)}
                        apiRef={apiRef}
                        rows={rows}
                        onCellClick={handleCellClick}
                        columns={columns}
                        // processRowUpdate={onEdit}
                        showCellVerticalBorder
                        onProcessRowUpdateError={(e) => console.log(e)}
                        disableRowSelectionOnClick
                        sx={{
                            minHeight: '150px'
                        }}
                    />
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <EntryButton disabled={!rows.length} endIcon={<DeleteIcon />} onClick={setClear}>clear</EntryButton>
                    <Box sx={{ display: 'flex', gap: '10px' }}>
                        <SubmitButton disabled={!rows.length} endIcon={<SendIcon />} onClick={onSubmit}>submit</SubmitButton>
                    </Box>
                </Box>

            </Box>
        </Box>
    )
}


export default TransferPage