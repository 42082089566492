import { instance } from "./interceptors"



export const allWarhousesUnit = async() => {
    const response = await instance.get('/api/branch/all')
    return response.data
}

export const warhouseProductsUnit = async(params) => {
    const query = (params) ? `/?branchType=${params.type}&branchId=${params.branch}` : ''
    const response = await instance.get('/api/warehouse'+query)
    return response.data
}


export const warhouseUpdateUnit = async(body) => {    
    const response = await instance.post('/api/warehouse/update', body)
    return response.data
}


export const transferWarehouseUnit = async(body) => {    
    const response = await instance.post('/api/warehouse/transfer', body)
    return response.data
}

