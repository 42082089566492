import { Box, Checkbox, styled, Typography } from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { setUpdateDocument } from "../store/gridDataSlice";
import clsx from "clsx";
import ButtonSection from "./ButtonsSection";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";


const style = {
    "*": {
        fontWeight: '600',
    },
    "& .edited": {
        backgroundColor: '#f1f1f1',
        color: '#1a3e72',
    },
    "& .local_edit": {
        backgroundColor: '#aeff7e',
        color: '#1a3e72',
    }
}

function DocumentEditGrid() {


    const dispatch = useDispatch()

    const { t } = useTranslation()

    const {
        role
    } = useSelector(store => store.authorization)

    const {
        document,
        documentProducts,
        dataLoad
    } = useSelector(store => store.documentProducts)

    const {
        updateDocumentData
    } = useSelector(store => store.gridData)

    // actual data
    const updatedProductsRef = useRef()
    const initProductRef = useRef()
    updatedProductsRef.current = updateDocumentData
    initProductRef.current = documentProducts

    let isManager = (role === 5)

    const apiRef = useGridApiRef()

    const rows = documentProducts.map((elem) => {
        let findData = updateDocumentData.find(data => data.product_document_id === elem.id)
        return {
            id: elem.product_branch.product_id,
            name: elem.product_branch.product_name,
            category_name: elem.product_branch.category,
            unit_type: elem.product_branch.unit_type,
            current_stock: (findData?.current_stock !== undefined) ? findData.current_stock : +(elem.current_stock),
            max_stock: +(elem.max_stock),
            needed_qnt: (findData?.needed_qnt !== undefined) ? findData.needed_qnt : +(elem.needed_qnt),
            localEdited: (findData?.localEdited !== undefined) ? findData.localEdited : false,
            edited: elem.edited,
            comment: (findData) ? findData.comment : false,
            current_quantity: +elem.decline_fixed_quantity || null
        }
    })

    const defaultColumns = [
        {
            field: 'category_name',
            headerName: t('grid.category'),
            editable: false,
            minWidth: 100,
            flex: 1,
        },
        { field: 'id', headerName: 'ID', maxWidth: 100, flex: 1 },
        {
            field: 'name',
            headerName: t('grid.product_name'),
            rowSpanValueGetter: () => { },
            editable: false,
            minWidth: 250,
            flex: 1,
        },
        {
            field: 'current_stock',
            headerName: t('grid.current_stock'),
            rowSpanValueGetter: () => { },
            type: 'number',
            cellClassName: (params) => {
                if (role === 5) return clsx('', {
                    edited: params.row.edited,
                    local_edit: params.row.localEdited,
                })
            },
            editable: role === 5,
            minWidth: 80,
            flex: 1,
        },
        {
            field: 'max_stock',
            headerName: t('grid.max_stock'),
            rowSpanValueGetter: () => { },
            type: 'number',
            editable: false,
            minWidth: 80,
            flex: 1,
        },
        {
            field: 'needed_qnt',
            headerName: t('grid.needed_qnt'),
            rowSpanValueGetter: () => { },
            type: 'number',
            editable: true,
            cellClassName: (params) => {
                return clsx('', {
                    edited: params.row.edited,
                    local_edit: params.row.localEdited,
                })
            },
            minWidth: 80,
            flex: 1,
        },
        {
            field: 'unit_type',
            headerName: t('grid.unit_type'),
            rowSpanValueGetter: () => { },
            editable: false,
            minWidth: 80,
            flex: 1,
        },
        {
            field: 'comment',
            headerName: t('grid.mark'),
            rowSpanValueGetter: () => { },
            renderCell: (params) => {
                return (
                    <Checkbox
                        checked={params.row.comment}
                        onChange={(event) => {
                            let findProductDocument = initProductRef.current.find(el => el.product_branch.product_id === params.row.id)
                            let savedProductIndex = updatedProductsRef.current.findIndex(el => el.product_document_id === findProductDocument.id)

                            if (savedProductIndex === -1) {
                                let localEditRow = {
                                    product_document_id: findProductDocument.id,
                                    needed_qnt: params.row.needed_qnt,
                                    comment: event.target.checked,
                                    document_id: document.id,
                                    localEdited: params.row.localEdited
                                }
                                dispatch(setUpdateDocument([...updatedProductsRef.current, localEditRow]))
                            } else {
                                let updatedData = [...updatedProductsRef.current]
                                updatedData[savedProductIndex] = {
                                    ...updatedData[savedProductIndex],
                                    comment: event.target.checked,
                                }
                                dispatch(setUpdateDocument(updatedData))
                            }

                        }}
                    />
                )
            },
            minWidth: 80,
            flex: 1,
        },
    ]

    const [columns, setColumns] = useState(defaultColumns)

    useEffect(() => {
        if (isManager) {
            setColumns([...columns, {
                field: 'current_quantity',
                headerName: t('grid.current_qnt'),
                rowSpanValueGetter: () => { },
                type: 'number',
                cellClassName: 'current_quantity',
                editable: false,
                minWidth: 80,
                flex: 1,
            }])
        }
    }, [])

    const onCeildEdit = (params) => {
        setTimeout(() => {
            const { id } = params;
            let editedRow = new Array(...apiRef.current.getRowModels())
                .map(el => el[1])
                .find(elem => elem.id === id)

            let findProductDocument = documentProducts.find(el => el.product_branch.product_id === id)
            let savedProductIndex = updateDocumentData.findIndex(el => el.product_document_id === findProductDocument.id)

            let isEdited = editedRow.needed_qnt !== +(findProductDocument.needed_qnt) || editedRow.current_stock !== +(findProductDocument.current_stock)

            if (savedProductIndex === -1) {
                let localEditRow = {
                    product_document_id: findProductDocument.id,
                    needed_qnt: editedRow.needed_qnt,
                    current_stock: editedRow.current_stock,
                    comment: editedRow.comment,
                    localEdited: isEdited
                }
                dispatch(setUpdateDocument([...updateDocumentData, localEditRow]))
            } else {
                let updatedData = [...updateDocumentData]
                updatedData[savedProductIndex] = {
                    ...updatedData[savedProductIndex],
                    needed_qnt: editedRow.needed_qnt,
                    current_stock: editedRow.current_stock,
                    comment: editedRow.comment,
                    localEdited: isEdited
                }
                dispatch(setUpdateDocument(updatedData))
            }
        })
    }


    const handleCellClick = useCallback((params) => {
        try {
            apiRef.current.startCellEditMode({
                id: params.id,
                field: params.field,
            })
        } catch (e) {
        }
    },
        [apiRef],
    );



    return (
        <Box sx={{ 
            width: '100%', 
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
                <Typography variant="h4">{document.branch?.name}</Typography>
            </Box>
            <Box
                sx={{
                    ...style,
                    height: '100%'
                }}
            >
                <DataGrid
                    columns={columns}
                    rows={rows}
                    loading={dataLoad}
                    isCellEditable={(params) => {
                        if (isManager) return true
                        return !params.row.edited
                    }}
                    apiRef={apiRef}
                    onCellEditStop={onCeildEdit}
                    onCellClick={handleCellClick}
                    showCellVerticalBorder
                    // hideFooter
                    unstable_rowSpanning
                />
            </Box>
            <ButtonSection />
        </Box>
    )
}

export default DocumentEditGrid