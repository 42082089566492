import { Box, Button, Typography } from "@mui/material"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { DataGrid } from "@mui/x-data-grid"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { getProductListAction, getVendorProductListAction } from "../../asyncActions/productAction"
import SubmitButton from "../../UI/SubmitButton"
import { setModal } from "../../store/modalSlice"


function VendorProductManagePage(){

    const dispatch = useDispatch()

    const {t} = useTranslation()

    const {
        productList
    } = useSelector((store => store.product.vendorProduct))

    const {
        dataLoad
    } = useSelector((store => store.product))


    useEffect(() => {
        dispatch(getVendorProductListAction())
    }, [dispatch])

    const rows = productList.map((product) => ({
        id: product.id,
        vendor: product.vendor.full_name,
        unit_type: product.unit_type,
        name: product.name,
    }))
    
    const columns = [
       {
            field: 'vendor',
            headerName: 'Vendor',
            editable: false,
            minWidth: 100,
            flex: 1,
        },
        { field: 'id', headerName: 'ID', maxWidth: 100, flex: 1 },
        {
            field: 'name',
            headerName: t('grid.product_name'),
            rowSpanValueGetter: () => { },
            editable: false,
            minWidth: 250,
            flex: 1,
        },
        {
            field: 'unit_type',
            headerName: t('grid.unit_type'),
            rowSpanValueGetter: () => { },
            editable: false,
            minWidth: 80,
            flex: 1,
        },
        {
            field: 'Action',
            headerName: t('grid.action'),
            rowSpanValueGetter: () => { },
            editable: false,
            maxWidth: 80,
            flex: 1,
            renderCell: (params) => {
                const {id} = params.row

                return (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <Link to={'/vendor/product/edit/'+id}>
                            <Button variant='outlined' color={'info'}>Edit</Button>                        
                        </Link>
                    </Box>
                )
            }
        }
    ]

    const handleCreateVendor = () => {
        const modalPayload = {
            active: true,
            content: 'vendorCreate'
        }
        dispatch(setModal(modalPayload))
    }

    return (
        <Box sx={{
            width: '100%',
        }}>
            <Typography align="center" variant="h4">Vendor-product manage</Typography>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: 2,
                p: 2
            }}>
                <Link to={'/vendor/product/create'}>
                    <SubmitButton>Create new vendor-product</SubmitButton>
                </Link>
                <SubmitButton onClick={handleCreateVendor}>Create new vendor</SubmitButton>
            </Box>  
            <Box>
            <DataGrid
                rows={rows}
                columns={columns}
                loading={dataLoad}
                unstable_rowSpanning
                showCellVerticalBorder
            />
            </Box>
        </Box>
    )
}


export default VendorProductManagePage