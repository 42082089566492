import { Container } from "@mui/material"
import Header from "./components/Header"
import { Outlet } from "react-router-dom"
import StyledContainer from "./components/StyledContainer"
import ModalPrompt from "./components/modal"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { getBranchAction, getBranchActionKitchen } from "./asyncActions/branchActions"


function Layout(){

    const {
        role
    } = useSelector(store => store.authorization)

    console.log(role)

    const dispatch = useDispatch()

    useEffect(() => {
        if (role === 1){
            dispatch(getBranchAction())
        } else if (role === 4){
            dispatch(getBranchActionKitchen())
        }
    }, [dispatch])

    return (
        <div >
            <Header/>
            <Container maxWidth='xl'>
                <StyledContainer>
                    <Outlet/>
                </StyledContainer>
            </Container>
            <ModalPrompt/>
        </div>
    )
}


export default Layout