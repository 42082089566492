import { Box, Button, } from "@mui/material"
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Localozation from "../components/Localisation";
import { setDate } from "../store/vendorSlice";
import { DocumentVendorProductsAction, documentVendorProductsEditAction } from "../asyncActions/vendorActions";
import { setModal } from "../store/modalSlice";
import { useTranslation } from "react-i18next";
import DataGridFullScreen from "../components/DataGridFullScreen";



function SupplyListPage() {

    const dispatch = useDispatch()

    const { t } = useTranslation()

    const apiRef = useGridApiRef()

    const {
        dateFilter,
        dataLoad,
        productDocumentVendotList,
    } = useSelector(store => store.vendor)

    const {
        role
    } = useSelector(store => store.authorization)


    const {
        postLoad
    } = useSelector(store => store.postLoad)

    useEffect(() => {
        const params = {
            date: dateFilter.date
        }
        dispatch(DocumentVendorProductsAction(params))
    }, [dateFilter.date])

    const rows = productDocumentVendotList.map(elem => ({
        id: elem.id,
        product: elem.vendor_product.name,
        unit_type: elem.vendor_product.unit_type,
        quantity: +elem.quantity,
        price: +elem.price,
        is_declined: elem.is_declined,
        comment: elem.comment
    }))

    const columns = [
        {
            field: 'id',
            headerName: 'id',
            maxWidth: 80,
        },
        {
            field: 'product',
            headerName: t('grid.product_name'),
            minWidth: 180,
            flex: 1
        },
        {
            field: 'unit_type',
            headerName: t('grid.unit_type'),
            maxWidth: 80,
        },
        {
            field: 'quantity',
            headerName: t('grid.qnt'),
            editable: role === 5,
            type: 'number',
            minWidth: 100,
            flex: 1
        },
        {
            field: 'price',
            headerName: t('grid.price'),
            editable: role === 5,
            type: 'number',
            minWidth: 100,
            flex: 1
        },
        {
            field: 'action',
            headerName: t('grid.action'),
            editable: false,
            maxWidth: 140,
            flex: 1,
            renderCell: (params) => {
                const { id, is_declined } = params.row

                const clickHandle = (type) => {
                    if (type === 'decline') {
                        // dispatch(setModal({ active: true, mode: '16', data: id }))
                        dispatch(setModal({ active: true, content: 'declineSupply', data: id }))
                    } else if (type === 'cancel') {
                        dispatch(documentVendorProductsEditAction(({ id: id, body: { is_declined: false } })))
                    }
                }
                return (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <Button onClick={() => clickHandle((is_declined ? 'cancel' : 'decline'))} variant='outlined' color={is_declined ? 'error' : 'info'}>{is_declined ? t('button.cancel') : t('button.decline')}</Button>
                    </Box>
                )
            }
        },
        {
            field: 'comment',
            headerName: t('grid.comment'),
            minWidth: 100,
            flex: 1
        },
    ];


    const onCeilEdit = (param) => {
        setTimeout(() => {
            let row = apiRef.current.getRowWithUpdatedValues(param.id)
            const data = {
                id: +row.id,
                body: {
                    quantity: +row.quantity,
                    price: +row.price,
                }
            }
            dispatch(documentVendorProductsEditAction(data))
        }, 100)
    }

    const handleCellClick = useCallback((params) => {
        try {
            apiRef.current.startCellEditMode({
                id: params.id,
                field: params.field,
            })
        } catch (e) {
        }
    },
        [apiRef],
    );


    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 5,
            height: '100%',
            width: '100%'
        }}>
            <Localozation>
                <DatePicker
                    disabled={dataLoad}
                    label={t('label.document_date')}
                    format="YYYY-MM-DD"
                    value={dateFilter.date}
                    onChange={(date) => {
                        dispatch(setDate(date))
                    }}
                />
            </Localozation>
            <Box sx={{ width: '100%', height: '100%' }}>
                <DataGridFullScreen
                    apiRef={apiRef}
                    columns={columns}
                    rows={rows}
                    loading={dataLoad || postLoad}
                    showCellVerticalBorder
                    hideFooter
                    onCellEditStop={onCeilEdit}
                    handleCellClick={handleCellClick}
                />
            </Box>
            {/* <StyledModal /> */}
        </Box>
    )
}

export default SupplyListPage


