import { Box } from "@mui/material"
import { Link } from "react-router-dom"
import SubmitButton from "../UI/SubmitButton"
import { useTranslation } from "react-i18next"


function ManagePage(){

    const {t} = useTranslation()

    const buttonList = [
        {id: 1, link: '/buffer/products', text: t('menu.buffer'), disabled: false},
        {id: 2, link: '/manage/warehouse', text: t('menu.warehouse'), disabled: false},
        {id: 3, link: '/manage/admission', text: t('menu.admission'), disabled: false},
        {id: 4, link: '/users', text: t('menu.users'), disabled: false},
        {id: 5, link: '/document/archive', text: t('menu.archive'), disabled: false},
        {id: 6, link: '/product', text: 'Product', disabled: false},
        {id: 7, link: '/vendor/product', text: 'Vendor Product', disabled: false},
    ]

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '30px'
        }}>
            {buttonList.map(btn => (
                <Link key={btn.id} to={btn.link}>
                    <SubmitButton disabled={btn.disabled}>{btn.text}</SubmitButton>
                </Link>
            ))}
        </Box>
    )
}


export default ManagePage