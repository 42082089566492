import { Box, Typography } from "@mui/material";
import SubmitButton from "../../../UI/SubmitButton";
import EntryButton from "../../../UI/EntryButton";
import { useDispatch, useSelector } from "react-redux";
import { setModal } from "../../../store/modalSlice";
import { useMemo } from "react";
import { archiveProductsDocumentAction, createDocuemntAction, deliveryProductsDocumentAction, submitProductsDocumentAction, updateDeclineDocumentProductsAction, updateDocumentProductsAction } from "../../../asyncActions/documentActions";
import { useTranslation } from "react-i18next";
import { transferWarehouseAction } from "../../../asyncActions/warhouseActions";

function TransferContent({ type }) {
    const {t} = useTranslation()

    const dispatch = useDispatch()

    const {
        data
    } = useSelector(store => store.modal)

    const submitHandle = () => {
        const modalPayload = {
            active: true,
            content: 'action',
            data: {}
        }
        modalPayload.data.text = 'Transfer was created'
        modalPayload.data.path = '/'
        dispatch(setModal(modalPayload))
        dispatch(transferWarehouseAction(data))
    }

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '25px',
            width: '450px'
        }}>
            <Typography  variant="h6">Did u want make transfer?</Typography>
            <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'space-between' }}>
                <EntryButton onClick={() => dispatch(setModal({ active: false }))}>{t('button.cancel')}</EntryButton>
                <SubmitButton onClick={submitHandle}>{t('button.submit')}</SubmitButton>
            </Box>
        </Box>
    )
}


export default TransferContent
