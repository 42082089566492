import { Box, Button, Typography } from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import ButtonsSection from "./ButtonsSection";
import { useCallback, useEffect, useState } from "react";
import { setDeclineDocumentData, setDeclineMode } from "../store/gridDataSlice";
import { useTranslation } from "react-i18next";


const style = {
    "*": {
        fontWeight: '600',
    },
    "& .edited": {
        backgroundColor: '#f1f1f1',
        color: '#1a3e72',
    },
    "& .local_edit": {
        backgroundColor: '#aeff7e',
        color: '#1a3e72',
    }
}

function DocuemntViewGrid() {


    const dispatch = useDispatch()

    const { t } = useTranslation()

    const {
        document,
        documentProducts,
        dataLoad
    } = useSelector(store => store.documentProducts)

    const {
        declineDocumentData,
        declineMode
    } = useSelector(store => store.gridData)

    const defaultColumns = [
        {
            field: 'category_name',
            headerName: t('grid.category'),
            minWidth: 100,
            editable: false,
            flex: 1,
        },
        {
            field: 'id',
            headerName: 'ID',
            maxWidth: 100,
            flex: 1

        },
        {
            field: 'name',
            headerName: t('grid.product_name'),
            rowSpanValueGetter: () => { },
            minWidth: 200,
            editable: false,
            flex: 1,
        },
        {
            field: 'current_stock',
            headerName: t('grid.current_stock'),
            rowSpanValueGetter: () => { },
            type: 'number',
            editable: false,
            minWidth: 80,
            flex: 1,
        },
        {
            field: 'max_stock',
            headerName: t('grid.max_stock'),
            rowSpanValueGetter: () => { },
            type: 'number',
            editable: false,
            minWidth: 100,
            flex: 1,
        },
        {
            field: 'needed_qnt',
            headerName: t('grid.needed_qnt'),
            rowSpanValueGetter: () => { },
            type: 'number',
            editable: false,
            minWidth: 100,
            flex: 1,
        },
        {
            field: 'unit_type',
            headerName: t('grid.unit_type'),
            rowSpanValueGetter: () => { },
            type: 'number',
            editable: false,
            flex: 1,
            minWidth: 80,
        }
    ]

    const [columns, setCoulmns] = useState(defaultColumns)

    useEffect(() => {
        console.log(declineMode);
        
        if (declineMode) {
            setCoulmns([...columns, {
                field: 'current_quantity',
                headerName: t('grid.current_qnt'),
                rowSpanValueGetter: () => { },
                type: 'number',
                cellClassName: 'current_quantity',
                editable: true,
                flex: 1,
                minWidth: 150,
            }])
        } else {
            setCoulmns(columns.filter(el => el.field !== 'current_quantity'))
        }
    }, [declineMode])


    const rows = documentProducts.map((elem) => {
        const edited = {
            id: elem.product_branch.product_id,
            name: elem.product_branch.product_name,
            category_name: elem.product_branch.category,
            unit_type: elem.product_branch.unit_type,
            current_stock: +(elem.current_stock),
            max_stock: +(elem.max_stock),
            needed_qnt: +(elem.needed_qnt)
        }
        if (declineMode) {
            let findData = declineDocumentData.find(data => elem.product_branch.product_id === data.id)
            edited.current_quantity = (findData) ? findData.current_quantity : null
        }
        return edited
    })

    const apiRef = useGridApiRef()


    function onCeildEdit(params) {
        setTimeout(() => {
            const { id } = params;
            let editedRow = new Array(...apiRef.current.getRowModels())
                .map(el => el[1])
                .find(elem => elem.id === id)
            dispatch(setDeclineDocumentData(editedRow))
        })
    }


    const handleCellClick = useCallback((params) => {
        try {
            apiRef.current.startCellEditMode({
                id: params.id,
                field: params.field,
            })
        } catch (e) {
        }
    },
        [apiRef],
    );




    return (
        <Box sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
                <Typography variant="h4">{document.branch?.name}</Typography>
            </Box>
            <Box
                sx={{ ...style,height: '100%'}}
            >
                <DataGrid
                    loading={dataLoad}
                    columns={columns}
                    rows={rows}
                    apiRef={apiRef}
                    onCellClick={handleCellClick}
                    onCellEditStop={onCeildEdit}
                    showCellVerticalBorder
                    // hideFooter
                    unstable_rowSpanning
                />
            </Box>
            <ButtonsSection />
        </Box>
    )
}

export default DocuemntViewGrid