import { createAsyncThunk } from "@reduxjs/toolkit"
import { allWarhousesUnit, transferWarehouseUnit, warhouseProductsUnit, warhouseUpdateUnit } from "../units/warhouseUnits"

export const warhouseProductsAction = createAsyncThunk('warehouse/products', async(params, {dispatch, rejectWithValue}) => {
    try {
        const response = await warhouseProductsUnit(params)
        return response
    } catch (e){
        return rejectWithValue(e.request?.response || 'Some error')
    }
})


export const allWarehouseListAction = createAsyncThunk('warehouse/all', async(_, {dispatch, rejectWithValue}) => {
    try {
        const response = await allWarhousesUnit()
        return response
    } catch (e){
        return rejectWithValue(e.request?.response || 'Some error')
    }
})



export const warhouseUpdateAction = createAsyncThunk('warehouse/update', async(body, {dispatch, rejectWithValue}) => {
    try {
        const response = await warhouseUpdateUnit(body)
        dispatch(warhouseProductsAction())
        return response
    } catch (e){
        return rejectWithValue(e.request?.response || 'Some error')
    }
})



export const transferWarehouseAction = createAsyncThunk('warehouse/transfer', async(body, {dispatch, rejectWithValue}) => {
    try {
        const response = await transferWarehouseUnit(body)
        return response
    } catch (e){
        return rejectWithValue(e.request?.response || 'Some error')
    }
})

