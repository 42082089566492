import { Box, Button, FormControl, InputLabel, MenuItem, Select, } from "@mui/material"
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Localozation from "../components/Localisation";
import { setBranchFilter, setDate } from "../store/vendorSlice";
import { deleteVendorDocumentAction, DocumentVendorProductsAction, documentVendorProductsEditAction, updateVendorDocumentAction } from "../asyncActions/vendorActions";
import { setModal } from "../store/modalSlice";
import { useTranslation } from "react-i18next";
import { allWarehouseListAction } from "../asyncActions/warhouseActions";
import DataGridFullScreen from "../components/DataGridFullScreen";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';



function AdmissionManagePage() {

    const dispatch = useDispatch()

    const { t } = useTranslation()

    const {
        warehouseList,
        branchList,
    } = useSelector((store) => store.warhouse)

    const {
        postLoad
    } = useSelector(store => store.postLoad)

    const apiRef = useGridApiRef()
    
    const {
        dateFilter,
        dataLoad,
        productDocumentVendotList,
        branchFilter
    } = useSelector(store => store.vendor)
    
    const branchOptions = branchFilter.branchType === 1 ? warehouseList : branchList
    const {
        role
    } = useSelector(store => store.authorization)

    useEffect(() => {
        if (branchFilter.branchType !== 0 && branchFilter.branchType !== 0){
            const params = {
                date: dateFilter.date,
                branchType: branchFilter.branchType,
                branchId: branchFilter.branchId,
            }
            dispatch(DocumentVendorProductsAction(params))
        }
    }, [dateFilter.date, branchFilter.branchId])

    useEffect(() => {
        dispatch(allWarehouseListAction())
    }, [dispatch])

    const rows = productDocumentVendotList.map(elem => ({
        id: elem.id,
        product: elem.vendor_product.name,
        unit_type: elem.vendor_product.unit_type,
        quantity: +elem.quantity,
        price: +elem.price,
        is_declined: elem.is_declined,
        comment: elem.comment
    }))

    const columns = [
        {
            field: 'id',
            headerName: 'id',
            maxWidth: 80,
        },
        {
            field: 'product',
            headerName: t('grid.product_name'),
            minWidth: 180,
            flex: 1
        },
        {
            field: 'unit_type',
            headerName: t('grid.unit_type'),
            maxWidth: 80,
        },
        {
            field: 'quantity',
            headerName: t('grid.qnt'),
            editable: role === 5,
            type: 'number',
            editable: false,
            minWidth: 100,
            flex: 1
        },
        {
            field: 'price',
            headerName: t('grid.price'),
            editable: role === 5,
            editable: false,
            type: 'number',
            minWidth: 100,
            flex: 1
        },
        {
            field: 'action',
            headerName: t('grid.action'),
            editable: false,
            maxWidth: 140,
            flex: 1,
            renderCell: (params) => {
                const { id, is_declined } = params.row

                const clickHandle = (type) => {
                    if (type === 'decline') {
                        dispatch(setModal({ active: true, content: 'declineSupply', data: id }))
                    } else if (type === 'cancel') {
                        dispatch(documentVendorProductsEditAction(({ id: id, body: { is_declined: false } })))
                    }
                }
                return (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <Button onClick={() => clickHandle((is_declined ? 'cancel' : 'decline'))} variant='outlined' color={is_declined ? 'error' : 'info'}>{is_declined ? t('button.cancel') : t('button.decline')}</Button>
                    </Box>
                )
            }
        },
        {
            field: 'comment',
            headerName: t('grid.comment'),
            minWidth: 100,
            flex: 1
        },
        {
            field: 'edit',
            headerName: t('grid.action'),
            flex: 1,
            renderCell: (params) => {
                const handleEdit = () => {
                    const data = {
                        text: params.row.product,
                        body: {
                            document_id: params.row.id,
                            quantity: params.row.quantity,
                            price: params.row.price
                        }
                    }
                    dispatch(setModal({active: true, data, content: 'editAdmission'}))
                }

                const handleDelete = () => {
                    const data = {
                        text: params.row.product,
                        body: params.row.id,
                    }
                    dispatch(setModal({active: true, data, content: 'deleteAdmission'}))
                }

                return(
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        gap: 1,
                        height: '100%',
                    }}>
                        <Button variant="outlined">                        
                            <EditIcon onClick={handleEdit} color="inherit"/>
                        </Button>
                        <Button color="error" variant="outlined">                        
                            <DeleteOutlineIcon onClick={handleDelete} color="error"/>
                        </Button>
                    </Box>
                )
            }

        }
    ];


    // const onCeilEdit = (param) => {
    //     setTimeout(() => {
    //         let row = apiRef.current.getRowWithUpdatedValues(param.id)
    //         const data = {
    //             id: +row.id,
    //             body: {
    //                 quantity: +row.quantity,
    //                 price: +row.price,
    //             }
    //         }
    //         dispatch(documentVendorProductsEditAction(data))
    //     }, 100)
    // }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 5,
            height: '100%',
            width: '100%'
        }}>
            <Localozation>
                <DatePicker
                    disabled={dataLoad}
                    label={t('label.document_date')}
                    format="YYYY-MM-DD"
                    value={dateFilter.date}
                    onChange={(date) => {
                        dispatch(setDate(date))
                    }}
                />
            </Localozation>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 5,
                justifyContent: 'center'
            }}>
                <FormControl sx={{ width: '250px' }}>
                    <InputLabel id="warhouse_type">Warehouse Type</InputLabel>
                    <Select
                        defaultValue={branchFilter.branchType}
                        disabled={dataLoad}
                        labelId="warhouse_type"
                        label="Warehouse Type"
                        onChange={(e) => dispatch(setBranchFilter({field: 'branchType', value: e.target.value}))}
                        required
                    >
                        <MenuItem value={1}>Warehouse</MenuItem>
                        <MenuItem value={2}>Branch</MenuItem>
                    </Select>
                </FormControl>

                <FormControl sx={{ width: '250px' }}>
                    <InputLabel id="warhouse_id">{branchFilter.branchType === 1 ? 'Warehouse' : 'Branch'} id</InputLabel>
                    <Select
                        defaultValue={branchFilter.branchType}
                        disabled={dataLoad}
                        labelId="warhouse_id"
                        label={branchFilter.branchType === 1 ? 'Warehouse' : 'Branch' + ' id'}
                        onChange={(e) => dispatch(setBranchFilter({field: 'branchId', value: e.target.value}))}
                        required
                    >
                        {branchOptions.map(branch => <MenuItem key={branch.id} value={branch.id}>{branch.name}</MenuItem>)}
                    </Select>
                </FormControl>
            </Box>
            <Box sx={{ width: '100%',  height: '100%' }}>
                <DataGridFullScreen
                    apiRef={apiRef}
                    columns={columns}
                    rows={rows}
                    loading={dataLoad || postLoad}
                    showCellVerticalBorder
                    hideFooter
                    // onCellEditStop={onCeilEdit}
                />
            </Box>
        </Box>
    )
}

export default AdmissionManagePage


