import { Box, CircularProgress } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useDispatch, useSelector } from "react-redux";
import { setDate } from "../store/branchSlice";
import { doucmentViewAction } from "../asyncActions/documentActions";
import { useEffect } from "react";
import DocumentList from "../components/DocumentList";
import Localozation from "../components/Localisation";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";




function DocumentListPage(){

    const dispatch = useDispatch()

    const {t} = useTranslation()

    const {
        dateFilter,
        documents,
        dataLoad
    } = useSelector(store => store.branch)

    useEffect(() => {
        dispatch(doucmentViewAction(dateFilter.date))
    }, [dateFilter.date])

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 5
        }}>
            <Localozation>
                <DatePicker
                    disabled={dataLoad}
                    label={t('label.document_date')}
                    format="YYYY-MM-DD"
                    value={dayjs(dateFilter.date) || null} 
                    onChange={(date) => {
                        dispatch(setDate(date))
                    }}
                />
            </Localozation>

            <Box sx = {{

            }}>
                {dataLoad ? <CircularProgress/> :
                    <DocumentList documents={documents}/>
                }
                
            </Box>
        </Box>
    )
}

export default DocumentListPage


