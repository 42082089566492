import { instance } from "./interceptors"


export const categoryListUnit = async() => {
    const response = await instance.get('/api/category/list')
    return response.data
}

export const categoryCreateUnit = async(data) => {
    const response = await instance.post('/api/category/create', data)
    return response.data
}
