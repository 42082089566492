import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getBranchAction } from "../asyncActions/branchActions"
import { Box, Typography } from "@mui/material"
import SubmitButton from "../UI/SubmitButton"
import { Link } from "react-router-dom"
import DocumentList from "./DocumentList"
import SendIcon from '@mui/icons-material/Send';
import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

function SalesManHome(){

    const {t} = useTranslation()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getBranchAction())
    }, [dispatch])

    const {
        branchName,
        branchAddress,
        documents
    } = useSelector(store => store.branch)
    
    const openCondition = documents.some(doc => doc.detail_date === dayjs().startOf('day').format('YYYY-MM-DD'))
    

    return(
        <Box sx={{display: 'flex', flexDirection: 'column', gap: 10}}>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Typography variant="h4">{branchName}</Typography>
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, alignSelf: 'center'}}>
                <Link to={openCondition ? '#' : '/create/document'}>
                    <SubmitButton fullWidth disabled={openCondition} endIcon={<SendIcon/>} variant="contained">{t('button.roam_balance')}</SubmitButton>
                </Link>
                <Link to={'/create/document/unschedule'}>
                    <SubmitButton fullWidth endIcon={<SendIcon/>} variant="contained">{t('button.uns_delivery')}</SubmitButton>
                </Link>
                <Link to={'/create/document/waste'}>
                    <SubmitButton fullWidth endIcon={<AutoDeleteIcon/>} variant="contained">{t('button.waste')}</SubmitButton>
                </Link>
                
            </Box>
            <DocumentList documents={documents}/>
        </Box>
    )
}


export default SalesManHome