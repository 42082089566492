import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { createDocuemntAction, createDocuemntWasteAction, createUnscheduledDocumentAction, deliveryProductsDocumentAction, submitProductsDocumentAction, updateDeclineDocumentProductsAction, updateDocumentProductsAction } from "../asyncActions/documentActions";
import { createVendorAction, createVendorDocumentAction, deleteVendorDocumentAction, DocumentVendorProductsAction, updateVendorDocumentAction } from "../asyncActions/vendorActions";
import { userCreateAction, userDeleteAction, userEditAction } from "../asyncActions/userActions";
import { transferWarehouseAction, warhouseProductsAction, warhouseUpdateAction } from "../asyncActions/warhouseActions";
import { createCategoryAction, createProductAction, createVendorProductAction, updateProductAction, updateVendorProductAction } from "../asyncActions/productAction";

const allSyncActions = [
    createDocuemntAction,
    updateDocumentProductsAction,
    submitProductsDocumentAction,
    deliveryProductsDocumentAction,
    createDocuemntWasteAction,
    createVendorDocumentAction,
    userCreateAction,
    userEditAction,
    userDeleteAction,
    updateDeclineDocumentProductsAction,
    DocumentVendorProductsAction,
    updateVendorDocumentAction,
    deleteVendorDocumentAction,
    createProductAction,
    createVendorProductAction,
    updateProductAction,
    updateVendorProductAction,
    createVendorAction,
    createCategoryAction,
    transferWarehouseAction,
    createUnscheduledDocumentAction,
    // warhouseProductsAction
]


const postLoadSlice = createSlice({
    name: 'postLoadSlice',
    initialState: {
        postLoad: false,
        error: null
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                isAnyOf(...allSyncActions.map(el => el.pending)),
                (state) => {
                    state.postLoad = true
                    state.error = null
                }
            )
            .addMatcher(
                isAnyOf(...allSyncActions.map(el => el.fulfilled)),
                (state) => {
                    state.postLoad = false
                }
            )
            .addMatcher(
                isAnyOf(...allSyncActions.map(el => el.rejected)),
                (state, action) => {
                    state.postLoad = false
                    console.log(action.payload)
                    try {
                        state.error = JSON.parse(action.payload).detail || 'Some error';
                    } catch (e) {
                        state.error = action.payload || 'Some error';
                    }
                }
            )
    }
})

export default postLoadSlice.reducer

export const {
    
} = postLoadSlice.actions

