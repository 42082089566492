import { instance } from "./interceptors"


export const updateVendorDocumentUnit = async(data) => {
    const response = await instance.post('/api/vendor/document/update', data)
    return response
}

export const deleteVendorDocumentUnit = async(document_id) => {
    const response = await instance.delete('api/vendor/document/delete/'+document_id)
    return response
}

export const getVendorListUnit = async() => {
    const response = await instance.get('/api/vendor/list')
    return response.data
}

export const vendorCreateUnit = async(data) => {
    const response = await instance.post('/api/vendor/create', data)
    return response.data
}