import { Box, TextField, MenuItem, FormControl, InputLabel, Select, Autocomplete, Typography } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import EntryButton from "../../UI/EntryButton";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { vendorListAction } from "../../asyncActions/vendorActions";
import SubmitButton from "../../UI/SubmitButton";
import { createVendorProductAction, getVendorProductItemAction, updateVendorProductAction } from "../../asyncActions/productAction";  // Импорт действия для получения данных продукта
import { clearForm } from "../../store/productSlice";
import { setModal } from "../../store/modalSlice";

function VendorProductDetailPage() {
    const { id } = useParams();
    const type = id ? 'edit' : 'create';
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { control, handleSubmit, setValue, formState: { errors } } = useForm();

    const { vendorList, form } = useSelector(store => store.product.vendorProduct);

    useEffect(() => {
        dispatch(vendorListAction());
        if (type === 'edit' && id) {
            dispatch(getVendorProductItemAction(id));
        }
        return () => {
            dispatch(clearForm())
        }
    }, [dispatch, id, type]);

    const vendorOptions = vendorList.map(el => ({ label: el.full_name, value: el.id }));

    // Если форма с данными уже загружена, предзагружаем значения в поля формы
    useEffect(() => {
        if (form?.name) {
            // const findCategory = categoryList.find(el => el.id == form.category)
            setValue("name", form.name || "");
            setValue("product_type", form.product_vendor_type || "");
            setValue("unit_type", form.unit_type || "")
            setValue("vendor", form.vendor || "")
        }
    }, [form, setValue]);


    const onSubmit = (data) => {
        let modalPayload = {
            active: true,
            data: {
                path: 'vendor/product',
                type: 'vendorProduct'
            }
        }
        if (type === 'create') {
            modalPayload.data.text = 'Подтвердить создание продукта вендора'
            modalPayload.content = 'vendorProductCreate'
            modalPayload.data.payload = data
            dispatch(setModal(modalPayload))
        } else if (type === 'edit') {
            const param = {
                id,
                body: data
            }
            modalPayload.data.text = 'Подтвердить изменение продукта вендора'
            modalPayload.content = 'vendorProductEdit'
            modalPayload.data.payload = param
            dispatch(setModal(modalPayload))
        }
    };

    return (
        <Box sx={{ width: '100%', maxWidth: '800px', margin: 'auto', p: 2 }}>
            <Typography variant="h4">{type === 'edit' ? `Edit vendor-product ${form?.name}` : "Create new vendor-product"}</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'start', my: 2 }}>
                <EntryButton onClick={() => navigate(-1)}>Back</EntryButton>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Имя продукта обязательно" }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            fullWidth
                            label="Имя"
                            margin="normal"
                            error={!!errors.name}
                            helperText={errors.name?.message}
                        />
                    )}
                />


                <Controller
                    name="unit_type"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Тип обязателен" }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            fullWidth
                            label="Тип"
                            margin="normal"
                            error={!!errors.unit_type}
                            helperText={errors.unit_type?.message}
                        />
                    )}
                />
                <FormControl fullWidth margin="normal">
                    <InputLabel>Product type</InputLabel>
                    <Controller
                        name="product_type"
                        control={control}
                        rules={{
                            required: "Product type requared",
                        }}
                        render={({ field }) => (
                            <Select
                                {...field}
                                label="Product type"
                                error={!!errors.branch}
                                defaultValue={[]}
                                value={field.value || []}
                            >
                                <MenuItem value={1}>Warehouse</MenuItem>
                                <MenuItem value={2}>Branch</MenuItem>

                            </Select>
                        )}
                    />
                    {errors.branch && <p style={{ color: 'red' }}>{errors.branch.message}</p>}
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <Controller
                        name={`vendor`}
                        control={control}
                        rules={{ required: "Vendor requared" }}
                        render={({ field }) => (
                            <Autocomplete
                                {...field}
                                label="Vendor"
                                options={vendorOptions}
                                onChange={(e, newValue) => setValue(`vendor`, newValue?.value || "")}
                                value={vendorOptions.find(option => option.value === field.value) || null}
                                renderInput={(params) => <TextField required {...params} label="Vendor" />}
                                fullWidth
                            />
                        )}
                    />
                    {errors.category && <p style={{ color: 'red' }}>{errors.category.message}</p>}
                </FormControl>
                <SubmitButton type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
                    {type === 'edit' ? "Save" : "Create"}
                </SubmitButton>
            </form>
        </Box>
    );
}

export default VendorProductDetailPage;
