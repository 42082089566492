import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { doucmentDashboardAction } from "../asyncActions/documentActions"
import { clearData } from "../store/documentProductsSlice"
import { useTranslation } from "react-i18next"

function DashboardPage() {

    const dispatch = useDispatch()

    const { t } = useTranslation()

    const {
        dashboardProducts,
        dataLoad
    } = useSelector(store => store.documentProducts)

    const [selectedType, setSelectedType] = useState(1)


    function selectHandle(e) {
        setSelectedType(e.target.value)
    }

    const rows = dashboardProducts

    useEffect(() => {
        dispatch(doucmentDashboardAction(selectedType))
    }, [selectedType])


    const columns = [
        {
            field: 'category_name',
            headerName: t('grid.category'),
            minWidth: 150,
            flex: 1,
            editable: false,
        },
        { 
            field: 'product_id', 
            headerName: 'id', 
            maxWidth: 80,
            flex: 1,
        },
        {
            field: 'product_name',
            headerName: t('grid.product_name'),
            rowSpanValueGetter: () => { },
            minWidth: 150,
            flex: 1,
            editable: false,
        },
        {
            field: 'unit_type',
            headerName: t('grid.unit_type'),
            rowSpanValueGetter: () => { },
            maxWidth: 80,
            flex: 1,
            editable: false,
        },
        {
            field: 'total_needed_qnt',
            headerName: t('grid.current_stock'),
            rowSpanValueGetter: () => { },
            minWidth: 150,
            flex: 1,
            type: 'number',
            default: 0,
            editable: false,

        }
    ]
    return (
        <Box sx={{ margin: '25px', display: 'flex', justifyContent: 'center', height: '100%',  width: '100%' }}>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '25px', height: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '30px', alignSelf: 'center' }}>
                    <FormControl sx={{ width: '150px' }}>
                        <InputLabel>{t('label.select_branch_type')}</InputLabel>
                        <Select
                            label={t('label.select_branch_type')}
                            value={selectedType}
                            onChange={(e) => selectHandle(e)}
                        >
                            <MenuItem value={1}>{t('label.branch')}</MenuItem>
                            <MenuItem value={2}>{t('label.kitchen')}</MenuItem>

                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{
                    display: 'flex', alignItems: 'center', gap: '25px', height: '100%'
                }}>
                    <DataGrid
                        getRowId={(row) => row.product_id}
                        rows={rows}
                        columns={columns}
                        loading={dataLoad}
                        showCellVerticalBorder
                        unstable_rowSpanning
                    />
                </Box>
            </Box>
        </Box>
    )
}


export default DashboardPage