import {
    AppBar,
    Avatar,
    Box,
    Container,
    Drawer,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Typography,
    List,
    ListItem,
    ListItemText
  } from '@mui/material';
  import MenuIcon from '@mui/icons-material/Menu';
  import ringoImage from '../assets/ringo_logo.png';
  import { Link } from 'react-router-dom';
  import { useDispatch, useSelector } from 'react-redux';
  import EntryButton from '../UI/EntryButton';
  import { logout } from '../store/authSlice';
  import LogoutIcon from '@mui/icons-material/Logout';
  import LoginIcon from '@mui/icons-material/Login';
  import { useTranslation } from 'react-i18next';
  import { useState } from 'react';
  
  function Header() {
    const { isAuthenticated, username, role } = useSelector(store => store.authorization);
  
    const languages = [
      { code: 'am', name: 'հայկ', flag: 'https://flagcdn.com/w320/am.png' },
      { code: 'ru', name: 'Русский', flag: 'https://flagcdn.com/w320/ru.png' },
      { code: 'en', name: 'English', flag: 'https://flagcdn.com/w320/us.png' },
    ];
  
    const { t, i18n } = useTranslation();
  
    const roleMenuBar = [
      { id: 1, path: '/admission', title: t('menu.admission'), roles: [1, 2] },
      { id: 2, path: '/dashboard', title: t('menu.dashboard'), roles: [2, 4, 5] },
      { id: 3, path: '/warehouse', title: t('menu.warehouse'), roles: [1, 2, 4] },
      { id: 4, path: '/manage', title: t('menu.manage'), roles: [5] },
      { id: 5, path: '/document/archive', title: t('menu.archive'), roles: [2] },
      { id: 6, path: '/transfer', title: 'Transfer', roles: [1] },
    ];
  
    const [anchorEl, setAnchorEl] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
  
    const dispatch = useDispatch();
  
    const handleOpenMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleCloseMenu = () => {
      setAnchorEl(null);
    };
  
    const handleLanguageChange = (languageCode) => {
      i18n.changeLanguage(languageCode);
      localStorage.setItem('lang', languageCode);
      handleCloseMenu();
    };
  
    const toggleDrawer = (open) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      setDrawerOpen(open);
    };
  
    const handleMenuItemClick = () => {
      setDrawerOpen(false);
    };
  
    const renderMenuItems = () => (
      <List>
        {isAuthenticated && (
          <ListItem button component={Link} to='/' onClick={handleMenuItemClick}>
            <ListItemText primary={t('menu.home')} />
          </ListItem>
        )}
        {role && roleMenuBar.filter(el => el.roles.includes(role)).map(menu => (
          <ListItem button key={menu.id} component={Link} to={menu.path} onClick={handleMenuItemClick}>
            <ListItemText primary={menu.title} />
          </ListItem>
        ))}
      </List>
    );
  
    return (
      <Box sx={{ flexGrow: 1, backgroundColor: '#f89e28' }}>
        <AppBar position="static" color="#f89e28">
          <Container maxWidth="xl" sx={{ padding: '15px 0px' }}>
            <Toolbar sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
              {/* Логотип, скрывается на мобильных устройствах */}
              <Box
                sx={{
                  position: 'absolute',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  display: { xs: 'none', md: 'flex' }, // Скрывается на мобильных
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Link to={'/'}>
                  <img width={100} src={ringoImage} alt="logo" />
                </Link>
              </Box>
  
              {/* Иконка для мобильного меню (бургер-меню) */}
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ display: 'flex' }}
                onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
  
              {/* Drawer для мобильного меню */}
              <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                sx={{
                  '& .MuiDrawer-paper': {
                    backgroundColor: '#f5f5f5', // Цвет фона для выдвигающегося контейнера
                  }
                }}
              >
                {renderMenuItems()}
              </Drawer>
  
              {/* Правая часть — язык и логин/логут */}
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  alignItems: 'center',
                  marginLeft: 'auto',
                }}
              >
                {isAuthenticated ? (
                  <>
                    {/* Языковое меню */}
                    <Box>
                      <IconButton onClick={handleOpenMenu}>
                        <Avatar
                          src={languages.find((lang) => lang.code === i18n.language)?.flag || 'https://flagcdn.com/w40/us.png'}
                          sx={{ border: '1px solid black' }}
                          alt="flag"
                          style={{ width: 40, height: 40 }}
                        />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                      >
                        {languages.map((lang) => (
                          <MenuItem
                            key={lang.code}
                            onClick={() => handleLanguageChange(lang.code)}
                          >
                            <Avatar
                              src={lang.flag}
                              alt={lang.name}
                              style={{ marginRight: '10px' }}
                            />
                            {lang.name}
                          </MenuItem>
                        ))}
                      </Menu>
                    </Box>
  
                    {/* Имя пользователя */}
                    <Typography variant="h5">{username}</Typography>
  
                    {/* Кнопка выхода */}
                    <EntryButton onClick={() => dispatch(logout())}>
                      <LogoutIcon />
                    </EntryButton>
                  </>
                ) : (
                  <Link to={'/login'}>
                    <EntryButton
                      endIcon={<LoginIcon />}
                      variant="contained"
                      color="error"
                    >
                      {t('button.login')}
                    </EntryButton>
                  </Link>
                )}
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </Box>
    );
  }
  
  export default Header;
  