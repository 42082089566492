import { Box, Button, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { warhouseProductsAction, warhouseUpdateAction } from "../asyncActions/warhouseActions";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import DataGridFullScreen from "../components/DataGridFullScreen";
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import SubmitButton from "../UI/SubmitButton";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import EntryButton from "../UI/EntryButton";

const style = {
    "*": {
        fontWeight: '600'
    },
    "& .edited": {
        backgroundColor: '#f1f1f1',
        color: '#1a3e72',
    },
    // "& .local_edit": {
    //     backgroundColor: '#aeff7e',
    //     color: '#1a3e72',
    // }
}

function WarehousePage() {

    const { t } = useTranslation()

    const apiRef = useGridApiRef()

    const dispatch = useDispatch()

    const {
        products,
        dataLoad
    } = useSelector((store) => store.warhouse)

    const {
        postLoad
    } = useSelector((store) => store.postLoad)

    const rows = products


    const defaultColumns = [

        { field: 'id', headerName: 'ID', width: 40 },
        {
            field: 'name',
            flex: 1,
            headerName: t('grid.product_name'),
            minWidth: 250,
            editable: false,
        },
        {
            field: 'unit_type',
            flex: 1,
            headerName: t('grid.product_name'),
            minWidth: 130,
            editable: false,
        },
        {
            field: 'avg_price',
            flex: 1,
            headerName: t('grid.avg_price'),
            type: 'number',
            minWidth: 130,
            editable: false,
        },
        {
            field: 'quantity',
            flex: 1,
            headerName: t('grid.qnt'),
            type: 'number',
            minWidth: 130,
            editable: false,
            cellClassName: (params) => {
                return clsx('', {
                    edited: params.row.edited,
                    local_edit: params.row.localEdited,
                })
            },
        }
    ]

    const [columns, setColumns] = useState(defaultColumns)
    const [correctMode, setCorrectMode] = useState(true)

    // const handleCellClick = useCallback((params) => {
    //     try {
    //         apiRef.current.startCellEditMode({
    //             id: params.id,
    //             field: params.field,
    //         })
    //     } catch (e) {
    //     }
    // },
    //     [apiRef],
    // );


    const correctHandle = (params) => {
        setTimeout(() => {
            const { row } = params
            if (typeof (+row.current_qnt) === 'number') {
                const body = {
                    id: row.id,
                    current_quantity: row.current_qnt
                }
                dispatch(warhouseUpdateAction(body))
            }
        })
    }
    const handleCorrectMode = () => {
        setCorrectMode((state) => !state)
        if (correctMode) {
            setColumns([
                ...defaultColumns,
                {
                    field: 'current_qnt',
                    headerName: t('grid.current_qnt'),
                    type: 'number',
                    minWidth: 130,
                    editable: true,
                },
                {
                    field: 'actions',
                    headerName: t('grid.action'),
                    minWidth: 130,
                    editable: false,
                    renderCell: (params) => {
                        return (
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%'
                            }}>
                                <Button variant="outlined" fullWidth onClick={() => correctHandle(params)}>
                                    <ChangeCircleIcon />
                                </Button>
                            </Box>
                        )
                    }
                },
            ])
        } else {
            setColumns(defaultColumns)
        }
    }


    useEffect(() => {
        dispatch(warhouseProductsAction())
    }, [dispatch])

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '25px',
            width: '100%',
            height: '100%'
        }}>
            <Typography variant="h4" align="center">{t('page_text.warhouse_inventory')}</Typography>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                height: '50px'
            }}>
                {correctMode ?
                    <SubmitButton onClick={handleCorrectMode}>{t('button.correct')}</SubmitButton> :
                    <EntryButton sx={{ px: 4, py: 1 }} onClick={handleCorrectMode}>{t('button.submit')}</EntryButton>
                }
            </Box>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 5,
                justifyContent: 'center'
            }}>
            </Box>
            <Box
                sx={{ ...style, height: '100%' }}
            >
                <DataGrid
                    loading={dataLoad || postLoad}
                    // handleCellClick={handleCellClick}
                    apiRef={apiRef}
                    columns={columns}
                    rows={rows}
                    showCellVerticalBorder
                />
            </Box>
        </Box>
    )
}

export default WarehousePage
