import { createSlice } from "@reduxjs/toolkit";
import { getBranchAction, getBranchActionKitchen } from "../asyncActions/branchActions";
import { doucmentViewAction, getDocumentListAction } from "../asyncActions/documentActions";
import dayjs from "dayjs";


const branchSlice = createSlice({
    name: 'branchSlice',
    initialState: {
        branchName: '',
        branchAddress: '',
        branchId: '',
        isKitchen: null,
        error: null,
        dataLoad: false,
        documents: [],
        // kitchen
        branchList: [],
        selectedBranch: null,
        // manager
        dateFilter: {
            date: dayjs().startOf('day')
        }
    },
    reducers: {
        setSelectedBranch(state, action){
            const findBranch = state.branchList.find(branch => branch.id === +action.payload)
            if (findBranch){
                const {
                    id,
                    name,
                    address,
                    isKitchen
                } = findBranch
    
                state.selectedBranch = name
                state.branchName = name
                state.branchAddress = address
                state.branchId = id
                state.isKitchen = isKitchen
            } else {
                state.selectedBranch = null
                state.branchId = null
            }
        },
        setDate(state, action){
            state.dateFilter.date = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getBranchAction.fulfilled, (state, action) => {
                const {
                    id,
                    name,
                    address,
                    isKitchen,
                    documents
                } = action.payload
                
                state.branchId = id
                state.branchName = name
                state.branchAddress = address
                state.isKitchen = isKitchen
                state.error = null
                state.dataLoad = false
                state.documents = documents

            })
            .addCase(getBranchAction.pending, (state) => {
                state.dataLoad = true
                state.error = null
            })
            .addCase(getBranchAction.rejected, (state, action) => {
                state.error = action.payload?.detail && 'some problems'
                state.dataLoad = false
            })
            .addCase(getDocumentListAction.fulfilled, (state, action) => {
                state.documents = action.payload
                state.dataLoad = false
            })
            .addCase(getDocumentListAction.pending, (state, action) => {
                state.dataLoad = true
            })
            .addCase(getDocumentListAction.rejected, (state, action) => {
                state.documents = []
                state.dataLoad = false
                state.error = action.payload?.detail && 'some problems'
            })

            .addCase(getBranchActionKitchen.fulfilled, (state, action) => {
                const {
                    branches,
                    documents
                } = action.payload
                state.branchList = branches
                state.documents = documents
                state.dataLoad = false
            })
            .addCase(getBranchActionKitchen.pending, (state, action) => {
                state.dataLoad = true
            })
            .addCase(getBranchActionKitchen.rejected, (state, action) => {
                state.dataLoad = false
                state.error = action.payload?.detail && 'some problems'
            })
            .addCase(doucmentViewAction.fulfilled, (state, action) => {
                state.documents = action.payload
                state.dataLoad = false
            })
            .addCase(doucmentViewAction.pending, (state, action) => {
                state.dataLoad = true
            })
            .addCase(doucmentViewAction.rejected, (state, action) => {
                state.documents = []
                state.dataLoad = false
                state.error = action.payload?.detail && 'some problems'
            })
    }   
})



export default branchSlice.reducer

export const {
    setSelectedBranch,
    setDate
} = branchSlice.actions
